import Vue, { VNode } from 'vue';

import DoublePaneLayout from '@/components/Admin/DoublePaneLayout/DoublePaneLayout';

interface Team {
  id: number;
  name: string;
}

interface TeamProps {
  teamMembers: Team[] | null;
}

const TeamSidebarItem = (context: any) => {
  const team = context.props.item as Team;

  return (
    <li class="sidebar-item">
      <router-link to={`/admin/team/${team.id}`}>
        {team.name}
      </router-link>
    </li>
  );
};

const TeamPage = Vue.extend({
  data(): TeamProps {
    return {
      teamMembers: null,
    };
  },

  methods: {
    handleTeamFiltering() {
      // TODO: Implement
    },

    handleTeamSorting() {
      // TODO: Implement
    },

    loadTeamMember() {
      // TODO: Replace
      this.teamMembers = [
        {
          id: 1,
          name: 'Team 1',
        },
        {
          id: 2,
          name: 'Team 2',
        },
        {
          id: 3,
          name: 'Team 3',
        },
      ];
    },

    searchTeamFormHandler(event: any) {
      event.preventDefault();
    },
  },

  mounted() {
    this.loadTeamMember();
  },

  name: 'team',

  render(): VNode {
    return (
      <DoublePaneLayout
        filterHandler={this.handleTeamFiltering}
        renderSidebarItem={TeamSidebarItem}
        search={{
          handler: this.searchTeamFormHandler,
          placeholder: 'Search team',
        }}
        sidebarItems={this.teamMembers}
        sortHandler={this.handleTeamSorting}
      >
        <router-view></router-view>
      </DoublePaneLayout>
    );
  },
});

export default TeamPage;
